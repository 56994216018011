<nav class="main-menu">
    <ul>
        <li
            #rla="routerLinkActive"
            *ngFor="let item of menuItems"
            [ngClass]="getItemNgClass(item, rla.isActive)"
            [routerLinkActiveOptions]="{ exact: true }"
            class="menu-item"
            routerLinkActive="active"
        >
            <div *ngIf="item.groupTitle; then group; else default"></div>

            <!-- Group title -->
            <ng-template #group>
                <span class="group-title">{{ item.title }}</span>
            </ng-template>
            <!-- end Group title -->

            <!-- Default item -->
            <ng-template #default>
                <div>
                    <a
                        (click)="toggle($event, item, this)"
                        *ngIf="item.routing"
                        class="item-link"
                        [ngClass]="{ 'disabled': item.disabled }"
                        href="#"
                        routerLink="{{ item.routing }}"
                    >
                        <span *ngIf="isHidden(item) != true" class="text">
                            {{ item.title }}

                            <mat-icon *ngIf="item.disabled"
                            aria-hidden="true"
                            class="mat-icon material-icons float-right premium-icon"
                            role="img"
                            >workspace_premium</mat-icon>
                        </span>

                        <span
                            *ngIf="item.badge && item.badge.text"
                            [ngStyle]="getStyles(item.badge)"
                            class="item-badge"
                            >{{ item.badge.text }}</span
                        >

                        <span class="icon">
                            <i
                                *ngIf="item.icon && item.icon.class"
                                [ngStyle]="getStyles(item.icon)"
                                class="icon-wrap {{ item.icon.class }}"
                                matTooltip="{{ item.title }}"
                                matTooltipPosition="right"
                            ></i>
                        </span>
                    </a>
                    <a
                        *ngIf="item.href"
                        class="item-link"
                        href="{{ item.href }}"
                        target="{{ item.target }}"
                    >
                        <span *ngIf="isHidden(item) != true" class="text">
                            {{ item.title }}
                        </span>

                        <span
                            *ngIf="item.badge && item.badge.text"
                            [ngStyle]="getStyles(item.badge)"
                            class="item-badge"
                            >{{ item.badge.text }}</span
                        >

                        <span class="icon">
                            <i
                                *ngIf="item.icon && item.icon.class"
                                [ngStyle]="getStyles(item.icon)"
                                class="icon-wrap {{ item.icon.class }}"
                                matTooltip="{{ item.title }}"
                                matTooltipPosition="right"
                            ></i>
                        </span>
                    </a>
                    <button
                        #tooltip="ngbPopover"
                        (hidden)="tooltipHidden()"
                        *ngIf="isHidden(item) != true"
                        [ngbPopover]="tooltipContent"
                        [popoverTitle]="item.title"
                        class="tooltip-button"
                        placement="right"
                        triggers="manual"
                    ></button>
                    <ng-template #tooltipContent>
                        {{ item.tooltip }}
                        <div class="popover-actions">
                            <button>Got it</button>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </li>
    </ul>
</nav>
