export const localStorageAccessConstant = {
    icao: 'icao',
    fbo: 'fbo',
    fboId: 'fboId',
    conductorFbo: 'conductorFbo',
    groupId: 'groupId',
    impersonatedrole: 'impersonatedrole',
    managerGroupId: 'managerGroupId',
    dismissedFavoriteAircrafts: 'dismissedFavoriteAircrafts',
    notifiedFavoriteAircraft: 'notifiedFavoriteAircraft',
    isSingleSourceFbo: 'isSingleSourceFbo',
    isNetworkFbo: 'isNetworkFbo',
}
