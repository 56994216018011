<nav class="navbar-wrap">
    <button (click)="openSidebar()" *ngIf="!isPublicView"
    [ngClass]="{ open: openedSidebar }"
    mat-icon-button aria-label="open side nav bar">
        <mat-icon>menu</mat-icon>
    </button>

    <div class="page-title">FBOLinx</div>
    <div *ngIf="!isPublicView">
        <button (click)="showWidget()" mat-stroked-button class="navbar-button live-chat">
            Live Chat
        </button>
    </div>
    <div *ngIf="fbo && fbo.fbo && fboAirport && fboAirport.icao">
        <div class="col 6 text-center page-info">
            {{ fboAirport.icao }}
        </div>
        <div class="col page-info">
            {{ fbo.fbo }}
        </div>
    </div>
    <div class="nav-items" *ngIf="!isPublicView">
        <!--Fuel order notifications-->
        <div class="nav-item fuel-order" [ngClass]="{ opened: fuelOrderNotificationsMenu.isOpened }">
            <a (click)="openFuelOrdersUpdate()" class="nav-link">
                <span *ngIf="fuelOrders && fuelOrders.length > 0">
                    <mat-icon [matBadge]="fuelOrders.length" matBadgeColor="accent" matBadgeSize="small" aria-hidden="false" aria-label="Fuel orders">local_gas_station</mat-icon>
                </span>
                <span *ngIf="!fuelOrders || fuelOrders.length == 0">
                    <mat-icon aria-hidden="false" aria-label="Fuel orders">local_gas_station</mat-icon>
                </span>
            </a>
            <div class="dropdown-menu">
                <h4 *ngIf="fuelOrders && fuelOrders.length > 0"
                    class="menu-title">
                    Incoming Orders
                </h4>
                <ul class="notifications-list" *ngIf="fuelOrders && fuelOrders.length > 0">
                    <li *ngFor="let fuelOrder of fuelOrders">
                        <a>
                            <mat-icon aria-hidden="false" aria-label="Inbound visit">flight</mat-icon>
                            <div class="content">
                                <span class="desc">Fueling Alert <strong>{{fuelOrder.tailNumber}} ({{fuelOrder.customerName}})</strong></span>
                                <span class="date">Gallons: {{(fuelOrder.quotedVolume <= 1 ? 'Captain\'s Request' : fuelOrder.quotedVolume)}} ({{(!fuelOrder.product || fuelOrder.product == '' ? 'JetA' : fuelOrder.product)}})</span>
                                <span class="date" *ngIf="fuelOrder.minutesUntilArrival <= 0">ETA: Now</span>
                                <span class="date" *ngIf="fuelOrder.minutesUntilArrival > 0">ETA: {{fuelOrder.hoursUntilArrival > 0 ? fuelOrder.hoursUntilArrival.toString() + ' hours' : fuelOrder.minutesUntilArrival.toString() + ' minutes'}}</span>
                            </div>
                        </a>
                    </li>
                </ul>
                <div class="menu-footer">
                    <a (click)="viewAllFuelOrdersClicked()" routerLink=".">View all orders</a>
                </div>
            </div>
        </div>
        <!--Bell notifications/alerts-->
        <div *ngIf="notificationVisible" [ngClass]="{ opened: needsAttentionMenu.isOpened }" class="nav-item notification-icon">
            <a (click)="openUpdate()" class="nav-link">
                <mat-icon aria-hidden="false" aria-label="Notifications">notifications</mat-icon>
                <span [ngClass]="{
                      marker:
                      (needsAttentionCustomersData &&
                      needsAttentionCustomersData.length>
                    0) ||
                    (hasLoadedJetACost && !currrentJetACostPricing) ||
                    (hasLoadedJetARetail && !currrentJetARetailPricing)
                    }">
                </span>
            </a>
            <div class="dropdown-menu">
                <h4 *ngIf="
                    (hasLoadedJetACost && !currrentJetACostPricing) ||
                    (hasLoadedJetARetail && !currrentJetARetailPricing) ||
                    (needsAttentionCustomersData &&
                    needsAttentionCustomersData.length>
                    0)
                    "
                    class="menu-title">
                    Notifications
                </h4>

                <ul class="notifications-list">
                    <!--JetA Cost Notification-->
                    <li *ngIf="hasLoadedJetACost && !currrentJetACostPricing">
                        <a>
                            <span class="icon sli-feed"></span>
                            <div class="content">
                                <span class="desc">Your cost pricing has expired!</span>
                                <span class="date"></span>
                                <div class="text-right">
                                    <button (click)="updatePricingClicked()"
                                            [view]="'accent'"
                                            class="mr-1"
                                            ni-button
                                            size="small">
                                        Update Pricing
                                    </button>
                                </div>
                            </div>
                        </a>
                    </li>

                    <!--JetA Retail Notification-->
                    <li *ngIf="
                            hasLoadedJetARetail && !currrentJetARetailPricing
                        ">
                        <a>
                            <span class="icon sli-feed"></span>
                            <div class="content">
                                <span class="desc">Your retail pricing has expired!</span>
                                <span class="date"></span>
                                <div class="text-right">
                                    <button (click)="updatePricingClicked()"
                                            [view]="'accent'"
                                            class="mr-1"
                                            ni-button
                                            size="small">
                                        Update Pricing
                                    </button>
                                </div>
                            </div>
                        </a>
                    </li>

                    <!--Customers without margins-->
                    <li *ngFor="let customer of needsAttentionCustomersData">
                        <a>
                            <span class="icon sli-exclamation"></span>
                            <div class="content">
                                <span class="desc">
                                    Needs Attention for
                                    <strong>{{ customer.company }}</strong>.
                                </span>
                                <span class="date"></span>
                                <div class="text-right">
                                    <button (click)="gotoCustomer(customer)"
                                            [view]="'success'"
                                            class="mr-1"
                                            ni-button
                                            size="small">
                                        See customer
                                    </button>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>

                <div class="menu-footer">
                    <a (click)="viewAllNotificationsClicked()" routerLink=".">View all notifications</a>
                </div>
            </div>
        </div>
        <!--favorite aircrafts notifications/alerts-->
        <div *ngIf="favoriteNotificationVisible" [ngClass]="{ opened: favoriteAircrafts.isOpened }" class="nav-item fuel-order">
            <a (click)="openfavoriteAircrafts()" class="nav-link">
                <span>
                    <mat-icon [matBadge]="favoriteAircraftIconBadgeText" matBadgeColor="accent" matBadgeSize="small" aria-hidden="false" aria-label="Favorite Aircrafts">star</mat-icon>
                </span>
            </a>
            <div class="dropdown-menu">
                <h4 class="menu-title">
                    Favorite Aircraft
                </h4>

                <ul class="notifications-list">
                    <li *ngFor="let flightwatch of favoriteAircraftsData">
                        <a>
                            <div class="content">
                                <span class="desc p-1">
                                    {{flightwatch.tailNumber}}
                                    <span *ngIf="flightwatch.icaoAircraftCode">
                                    ({{flightwatch.icaoAircraftCode}})
                                    </span> has been detected ETA: {{flightwatch.etaLocal | date: "HH:mm" }}
                                </span>
                                <div class="text-right p-1">
                                    <button (click)="removeFavoriteAircraft(flightwatch)"
                                    [view]="'error'"
                                    class="mr-1"
                                    ni-button
                                    size="small">
                                        Dismiss
                                    </button>
                                    <button (click)="goToFlightWatch(flightwatch)"
                                    [view]="'accent'"
                                    class="mr-1"
                                    ni-button
                                    size="small">
                                        Show me
                                    </button>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div [ngClass]="{ opened: accountProfileMenu.isOpened }" class="nav-item user-name">
            <a (click)="toggleProfileMenu()" class="nav-link">
                <div *ngIf="userFullName" class="name">{{ userFullName }}</div>
            </a>
            <mat-spinner *ngIf="isLocationsLoading" diameter="30"></mat-spinner>
            <div *ngIf="!isLocationsLoading" class="dropdown-menu mini-menu">
                <ul>
                    <li>
                        <a (click)="accountProfileClicked()"
                           style="cursor: pointer">
                            <span class="icon sli-settings"></span> Account
                            Profile
                        </a>
                    </li>
                    <li *ngIf="
                        currentUser &&
                        currentUser.impersonatedRole>
                        0 &&
                        fboAirport
                        "
                        class="nav-sub-item">
                        <a style="cursor: pointer">
                            <span class="icon sli-arrow-left"></span>
                            Switch Location
                        </a>
                        <mat-list class="nav-sub-item-menu">
                            <mat-list-item (click)="changeLocation(location)"
                                           *ngFor="let location of locations">
                                <span *ngIf="location.oid == fboAirport.fboid"
                                      class="icon sli-flag"></span>&nbsp;
                                {{ location.icao }}
                            </mat-list-item>
                        </mat-list>
                    </li>
                    <li *ngIf="
                            currentUser && currentUser.impersonatedRole === userRole.Primary
                        ">
                        <a (click)="stopManagingFBOClicked()"
                           style="cursor: pointer">
                            <span class="icon sli-settings"></span>
                            Stop Managing FBO
                        </a>
                    </li>
                    <li *ngIf="
                            currentUser && currentUser.impersonatedRole === userRole.GroupAdmin
                        ">
                        <a (click)="stopManagingGroupClicked()"
                           style="cursor: pointer">
                            <span class="icon sli-settings"></span>
                            Stop Managing Group
                        </a>
                    </li>
                    <li *ngIf="isLobbyViewVisible()">
                        <a target="_blank" [routerLink]="['/public-layout/lobby-view']"
                        style="cursor: pointer">
                            <span class="icon sli-map"></span>
                            Lobby View
                        </a>
                    </li>
                    <li>
                        <a (click)="logout()" style="cursor: pointer">
                            <span class="icon sli-logout"></span> Log Out
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div (click)="close()" class="items-overlay"></div>
    </div>
</nav>
<ng-container *ngIf="favoriteNotificationVisible">
    <app-incoming-favorite-aircraft-info></app-incoming-favorite-aircraft-info>
</ng-container>
