<section>
    <div *ngIf="fbosData || currentFbo">
        <div *ngIf="currentFbo">
            <app-fbos-edit
                (cancelClicked)="cancelFboEditClicked()"
                (saveClicked)="saveFboEditClicked()"
                [embed]="embed"
                [fboAirportInfo]="currentFboAirport"
                [fboInfo]="currentFbo"
                [groupInfo]="groupInfo"
            ></app-fbos-edit>
        </div>
        <div *ngIf="!currentFbo && fbosData">
            <app-fbos-grid
                (editFboClicked)="editFboClicked($event)"
                [fbosData]="fbosData"
                [groupInfo]="groupInfo"
            ></app-fbos-grid>
        </div>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
</section>
