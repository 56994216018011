export const localStorageAccessConstant = {
    icao: 'icao',
    fbo: 'fbo',
    fboId: 'fboId',
    conductorFbo: 'conductorFbo',
    groupId: 'groupId',
    impersonatedrole: 'impersonatedrole',
    managerGroupId: 'managerGroupId',
    favoriteAircrafts: 'favoriteAircrafts',
    accountType: 'accountType',
    dismissedFavoriteAircrafts: 'dismissedFavoriteAircrafts',
    notifiedFavoriteAircraft: 'notifiedFavoriteAircraft',
    isSingleSourceFbo: 'isSingleSourceFbo',
    isNetworkFbo: 'isNetworkFbo',
    currentUser: 'currentUser',
    decimalPrecision: 'decimalPrecision',
    isJetNetIntegrationEnabled: 'isJetNetIntegrationEnabled'
}
